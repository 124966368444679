.homeContainer {
  background-image: url("./../Images/Background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 140vh;
  padding-bottom: 350px;
}
.HomeContents {
  height: 150vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.homeHeading {
  font-size: 70px;
}
.home-request-demo-button {
  background: rgb(255, 215, 0);
  padding: 6px 20px 9px;
  cursor: pointer !important;
  border-radius: 6px;
  width: 240px;
  text-align: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 22px;
  /* font-family: sans-serif; */
  box-shadow: 3px 4px 10px rgba(214, 210, 1, 0.552);
  color: rgb(0, 0, 0); /* Ensure text is readable */
  border: none; /* Remove default border */
  /* box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.3); */
  font-weight: 500;
}
.homePara {
  font-size: 24px;
  color: rgb(227, 240, 250);
  margin: 50px 0;
  font-weight: 300;
}
.HomeSecBtn {
  font-size: 17px;
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  /* cursor: pointer; */
  padding: 10px 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.HomeSecBtnCol {
  background: linear-gradient(to right, #7446fd, #7d60f5, #8173ec);
}
.HomeSecBtn:active {
  background-color: rgba(255, 255, 255, 0.236);
}
.homeBtnSec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 250px;
  max-width: 450px;
}

.PhoneImage {
  max-width: 99vw;
}

.HomeTopcontents {
  padding: 20px;
}

.homeForm-container {
  padding-top: 20px;
}

/* .HomeImg {
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* Extra small devices (phones) */
@media only screen and (max-width: 600px) {
  /* Styles for phones */
  .HomeContents {
    height: 115vh;
  }
  .homeHeading {
    font-size: 40px;
  }
  .homePara {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .homeContainer {
    padding-bottom: 200px;
    background-image: none;
    background-color: #4470ad;
    padding-bottom: 350px;
  }
  .home-request-demo-button {
    width: 150px;
    font-size: 20px;
  }
}

/* Small devices (tablets) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  /* Styles for tablets */
}

/* Large devices (large desktops, TVs) */
@media only screen and (min-width: 1025px) {
  /* Styles for large desktops and TVs */
  .PhoneImage {
    max-width: 45vw;
    position: relative;
    margin-right: 3vw;
    margin-top: -150px;
  }
  .HomeTopcontents {
    /* flex: 3; */
    padding: 0px 0 0 60px;
    max-width: 46vw;
    padding-bottom: 150px;
    margin-top: 50px;
  }
  .HomeContents {
    height: 100vh;
  }
}

@media only screen and (min-width: 2425px) {
  /* Styles for large desktops and TVs */
}
