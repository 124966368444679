#Pricing {
  margin: 50px 0px 50px 0;
}

.PricingTitle {
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  margin-top: 40px;
}
.PricingPara1 {
  font-size: 28px;
  color: #505050;
  text-align: center;
  max-width: 1156px;
}

.pricingContaineritems {
  height: 650px;
  border-radius: 15px;
  margin: 20px;
  max-width: 410px;
  min-width: 350px;
}

.PricingImage {
  background-image: url("../Images/pricingImage.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 30px 15px;
  max-width: 1250px;
  min-width: 360px;
  height: 700px;
  border-radius: 15px;
}

/* Extra small devices (phones) */
@media only screen and (max-width: 600px) {
  /* Styles for phones */
  .PricingTitle {
    font-size: 32px;
    margin-top: 20px;
  }
  .PricingPara1 {
    font-size: 24px;
  }
  .pricingContaineritems {
    margin: 10px;
    min-width: 250px;
  }
}

/* Small devices (tablets) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  /* Styles for tablets */
  .PricingTitle {
    font-size: 38px;
  }
  .PricingPara1 {
    font-size: 26px;
  }
}

/* Large devices (large desktops, TVs) */
@media only screen and (min-width: 1025px) {
  /* Styles for large desktops and TVs */
  .pricingContaineritems {
    min-width: 410px;
  }
}
