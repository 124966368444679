.StructureContainer {
  display: flex;
  /* gap: 30px; */
  align-items: flex-start;
  justify-content: center;
  margin: 0 5vw;
}

.StructureCardConatiner {
  display: flex;
  flex-direction: row;
}

.StructureCard {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.StructureCardImage {
  max-width: 150px;
  max-height: 150px;
  min-width: 50px;
  min-height: 50px;
  margin: 0 3vw;
}

.StructureCardContent {
  color: #c0c0c0;
  text-align: center;
  max-width: 250px;
}

.RightArrow {
  max-width: 100px;
  max-height: 15px;
  margin-top: 75px;
}

.DownArrow {
  display: none;
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 1100px) {
  .DownArrow {
    display: block;
    max-width: 10px;
    max-height: 50px;
  }

  .RightArrow {
    display: none;
  }

  .StructureContainer {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .StructureCardImage {
    max-width: 150px;
    max-height: 150px;
    min-width: 50px;
    min-height: 50px;
  }
}
