.FotterContainer {
  background-image: url("../Images/fotterImage.png");
  width: 100%;
  min-height: 500px;
  background-size: cover;
  padding-top: 180px;
  display: flex;
  flex-wrap: wrap;
  color: white;
  justify-content: center;
}
.fotter1 {
  margin-top: 50px;
}
.FotterBox {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: #d9d9d9;
}
.FOttercontent {
  color: white;
  font-size: 19px;
  max-width: 550px;
  text-align: justify;
}
.fotterTitle {
  color: white;
  font-size: 60px;
}
.fottersections {
  padding: 50px;
}
.FotterLine {
  height: 100%;
  border-left: 1px solid #ffffff3d;
}

.FotterPricingSection {
  padding-top: 30px;
  max-width: 400px;
}

.FotterSocialLinks {
  display: flex;
  gap: 20px;
  margin-top: 50px;
  flex-wrap: wrap;
}

.FotterIcon {
  max-width: 30px;
  max-height: 30px;
}

.FotterAddress {
  display: flex;
  gap: 5px;
  margin: 15px 0;
  font-size: 20px;
}

/* Extra small devices (phones) */
@media only screen and (max-width: 600px) {
  /* Styles for phones */
  .FotterBox {
    width: 50px;
    height: 50px;
  }
  .fotterTitle {
    font-size: 35px;
  }
  .fottersections {
    padding: 20px;
  }
  .FotterLine {
    border-left: 0px solid #ffffff3d;
  }
  .lineHidder {
    display: none;
  }
  #Fotter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

/* Small devices (tablets) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  /* Styles for tablets */
  .fotterTitle {
    font-size: 50px;
  }
  .FotterBox {
    width: 65px;
    height: 65px;
  }
  .FotterLine {
    display: none;
  }
  .lineHidder {
    display: none;
  }
  #Fotter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

/* Large devices (large desktops, TVs) */
@media only screen and (min-width: 1025px) {
  /* Styles for large desktops and TVs */
}

@media only screen and (min-width: 2425px) {
  /* Styles for large desktops and TVs */
  .FotterContainer {
    padding-top: 250px;
  }
}
