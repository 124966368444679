.form-container {
  max-width: 310px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.507);
  margin: 0 auto;
  margin-top: 100px;
  backdrop-filter: blur(30px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(30px); /* For Safari compatibility */
}

.form-innercontainer {
  display: flex;
  flex-direction: column;
}

.demo-form-input {
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  border-radius: 40px;
  border: 1px solid #ccc;
}

.demo-form-input:focus {
  border-color: blue;
  outline: none;
  border-width: 1px;
}

.form-heading {
  /* color: white; */
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 20px;
}

.error {
  border-color: red !important; /* Ensures the red border overrides other styles */
}

.submit-button {
  margin: 0 60px;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 40px;
  /* background-color: blue; */
  background: rgba(232, 24, 24, 0.829);

  color: white;
  cursor: pointer;
}

.submit-button:hover {
  background-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: -5px;
  margin-left: 5px;
}
@media only screen and (max-width: 600px) {
  .form-container {
    max-width: 300px;
  }
}
