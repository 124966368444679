.NavBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 30px 10px 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.334);
  background: #4470ad;
  align-items: center;
  user-select: none;
}
.NavbarLogo {
  max-width: 250px;
  min-width: 100px;
}
.NavBarLogo {
  font-size: 30px;
  font-weight: 500;
  color: white;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  padding-left: 10px;
}
.NavbarButtonLight {
  font-size: 17px;
  background: none;
  border: none;
  color: white;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
  padding: 5px;
  border-radius: 10px;
}
.NavbarButtonLight:hover {
  color: antiquewhite;
}
.NavbarButtonDark {
  font-size: 17px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.451);
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
}
.NavbarButtonLight:active {
  background-color: rgba(255, 255, 255, 0.236);
}
.NavbarButtonDark:active {
  background-color: rgba(255, 255, 255, 0.236);
}
.ButtonBorder {
  background: linear-gradient(to right, #264166, #313986, #5648c1);
  padding: 13px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 6px;
  font-size: 14px;
}

.ButtonSec {
  display: flex;
  gap: 40px;
  align-items: center;
}

.dropDownSec {
  display: none;
}
@media screen and (max-width: 950px) {
  .ButtonSec {
    display: none;
  }
  .dropDownSec {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .NavBarContainer {
    padding: 10px;
  }
}
.navbarButtons {
  text-decoration: none;
  color: black;
}

.navbarButtons :active {
  background-color: rgba(255, 255, 255, 0.236);
}
