.CommonBoxouterLayer {
  background-color: white;
  display: flex;

  flex-direction: column;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 22px;
  min-width: 140px;
  gap: 20px;
  margin: 10px;
}

#About {
  margin-top: 100px;
}
.CommonBoxType1 {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 140px;
  gap: 5px;
  margin: 0 20px;
  height: 225px;
}
.AboutTitle {
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  max-width: 883px;
  padding: 50px 10px 20px 10px;
}

.AboutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AboutBox1 {
  max-width: 280px;
  min-width: 150px;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 15px 10px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AboutContiner2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AboutCardSection {
  margin: 0 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
  align-items: center;
}
.AboutTitle1 {
  color: #3d6cd7;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 20px;
}
.commonBoxIconContainer {
  width: 65px;
  height: 60px;
  background-color: #3d6cd7;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InnercardConatiner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
}

.commonBoxIcons {
  width: 45%;
  height: 55%;
  padding: 35px;
}

.commonBoxHeading {
  color: #3d6cd7;
  font-size: 32px;
  font-weight: 500;
  /* text-align: center; */
  max-height: 100px;
}
.commonBoxPara {
  max-width: 480px;
  font-size: 18px;
  text-align: justify;
  color: #505050;
}
.About2Title,
.About2Content,
.AboutCardSection {
  margin: 20px 0;
}
.demo-Form-container {
  margin-top: -400px;
}
.About2Content {
  margin: 30px 0 50px;
}

.About2Title {
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  max-width: 983px;
  padding: 0 10px;
}

.About2Content {
  font-size: 28px;
  color: #505050;
  text-align: center;
  max-width: 1216px;
}
.CommonBoxType1 .commonBoxHeading {
  text-align: left;
}
.AboutAllCards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

/* Extra small devices (phones) */
@media only screen and (max-width: 600px) {
  /* Styles for phones */
  .AboutTitle {
    font-size: 32px;
  }

  .AboutItems {
    display: none;
  }
  .AboutMiddleCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .AboutBox1 {
    margin: 0 20 0 20px;
  }
  .About2Title {
    font-size: 34px;
  }
  .About2Content {
    text-align: justify;
    padding: 20px;
    font-size: 24px;
  }

  .commonBoxIconContainer {
    width: 70px;
    height: 65px;
    border-radius: 16px;
  }
  .CommonBoxType1 {
    background-color: white;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 140px;
    margin: 20px;
    padding: 20px;
    max-height: 250px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    height: auto;
  }

  .commonBoxHeading {
    font-size: 26px;
  }

  .InnercardConatinermobile {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

/* Small devices (tablets) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  /* Styles for tablets */
  .AboutTitle {
    font-size: 38px;
  }
  .About2Content {
    font-size: 26px;
  }
  .AboutItems {
    display: none;
  }

  .AboutMiddleCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .CommonBoxType1 {
    background-color: white;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 140px;
    margin: 20px;
    padding: 20px;
    max-height: 250px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    height: auto;
  }
}

/* Large devices (large desktops, TVs) */
@media only screen and (min-width: 1025px) {
  /* Styles for large desktops and TVs */
  .AboutItems1 {
    display: none;
  }
}
